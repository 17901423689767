export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"charset":"utf-8"},{"name":"theme-color","content":"#f7cc00"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"msapplication-TileImage","content":"/favicon/ms-icon-144x144.png"}],"link":[{"rel":"apple-touch-icon","sizes":"57x57","href":"/favicon/apple-icon-57x57.png"},{"rel":"apple-touch-icon","sizes":"60x60","href":"/favicon/apple-icon-60x60.png"},{"rel":"apple-touch-icon","sizes":"72x72","href":"/favicon/apple-icon-72x72.png"},{"rel":"apple-touch-icon","sizes":"76x76","href":"/favicon/apple-icon-76x76.png"},{"rel":"apple-touch-icon","sizes":"114x114","href":"/favicon/apple-icon-114x114.png"},{"rel":"apple-touch-icon","sizes":"120x120","href":"/favicon/apple-icon-120x120.png"},{"rel":"apple-touch-icon","sizes":"144x144","href":"/favicon/apple-icon-144x144.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"/favicon/apple-icon-152x152.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-icon-180x180.png"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"/favicon/android-icon-192x192.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"96x96","href":"/favicon/favicon-96x96.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon/favicon-16x16.png"},{"rel":"manifest","href":"/favicon/manifest.json"}],"style":[],"script":[],"noscript":[],"charset":"utf-8","robots":"noindex, nofollow","viewport":"width=device-width, initial-scale=1, shrink-to-fit=no"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'