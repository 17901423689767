import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91id_93SwOr1QJdqzMeta } from "/app/pages/client/[id].vue?macro=true";
import { default as indexKcNfx26hoCMeta } from "/app/pages/client/index.vue?macro=true";
import { default as errorcxo3GvC8JMMeta } from "/app/pages/error.vue?macro=true";
import { default as _91reference_93GGSQSmQknhMeta } from "/app/pages/estimate/[reference].vue?macro=true";
import { default as _1_45client_45selectJZD8WtWnoGMeta } from "/app/pages/estimate/draft/1-client-select.vue?macro=true";
import { default as _2_45client_45form7Fl4AIwPFZMeta } from "/app/pages/estimate/draft/2-client-form.vue?macro=true";
import { default as _3_45boat_45selectdsQmODSE2SMeta } from "/app/pages/estimate/draft/3-boat-select.vue?macro=true";
import { default as _4_45boat_45formZGFG7jiG9DMeta } from "/app/pages/estimate/draft/4-boat-form.vue?macro=true";
import { default as indexU5dWuhranxMeta } from "/app/pages/estimate/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
export default [
  {
    name: indexi8nCATZISJMeta?.name ?? "admin",
    path: indexi8nCATZISJMeta?.path ?? "/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SwOr1QJdqzMeta?.name ?? "client-id",
    path: _91id_93SwOr1QJdqzMeta?.path ?? "/client/:id()",
    meta: _91id_93SwOr1QJdqzMeta || {},
    alias: _91id_93SwOr1QJdqzMeta?.alias || [],
    redirect: _91id_93SwOr1QJdqzMeta?.redirect || undefined,
    component: () => import("/app/pages/client/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKcNfx26hoCMeta?.name ?? "client",
    path: indexKcNfx26hoCMeta?.path ?? "/client",
    meta: indexKcNfx26hoCMeta || {},
    alias: indexKcNfx26hoCMeta?.alias || [],
    redirect: indexKcNfx26hoCMeta?.redirect || undefined,
    component: () => import("/app/pages/client/index.vue").then(m => m.default || m)
  },
  {
    name: errorcxo3GvC8JMMeta?.name ?? "error",
    path: errorcxo3GvC8JMMeta?.path ?? "/error",
    meta: errorcxo3GvC8JMMeta || {},
    alias: errorcxo3GvC8JMMeta?.alias || [],
    redirect: errorcxo3GvC8JMMeta?.redirect || undefined,
    component: () => import("/app/pages/error.vue").then(m => m.default || m)
  },
  {
    name: _91reference_93GGSQSmQknhMeta?.name ?? "estimate-reference",
    path: _91reference_93GGSQSmQknhMeta?.path ?? "/estimate/:reference()",
    meta: _91reference_93GGSQSmQknhMeta || {},
    alias: _91reference_93GGSQSmQknhMeta?.alias || [],
    redirect: _91reference_93GGSQSmQknhMeta?.redirect || undefined,
    component: () => import("/app/pages/estimate/[reference].vue").then(m => m.default || m)
  },
  {
    name: _1_45client_45selectJZD8WtWnoGMeta?.name ?? "estimate-draft-1-client-select",
    path: _1_45client_45selectJZD8WtWnoGMeta?.path ?? "/estimate/draft/1-client-select",
    meta: _1_45client_45selectJZD8WtWnoGMeta || {},
    alias: _1_45client_45selectJZD8WtWnoGMeta?.alias || [],
    redirect: _1_45client_45selectJZD8WtWnoGMeta?.redirect || undefined,
    component: () => import("/app/pages/estimate/draft/1-client-select.vue").then(m => m.default || m)
  },
  {
    name: _2_45client_45form7Fl4AIwPFZMeta?.name ?? "estimate-draft-2-client-form",
    path: _2_45client_45form7Fl4AIwPFZMeta?.path ?? "/estimate/draft/2-client-form",
    meta: _2_45client_45form7Fl4AIwPFZMeta || {},
    alias: _2_45client_45form7Fl4AIwPFZMeta?.alias || [],
    redirect: _2_45client_45form7Fl4AIwPFZMeta?.redirect || undefined,
    component: () => import("/app/pages/estimate/draft/2-client-form.vue").then(m => m.default || m)
  },
  {
    name: _3_45boat_45selectdsQmODSE2SMeta?.name ?? "estimate-draft-3-boat-select",
    path: _3_45boat_45selectdsQmODSE2SMeta?.path ?? "/estimate/draft/3-boat-select",
    meta: _3_45boat_45selectdsQmODSE2SMeta || {},
    alias: _3_45boat_45selectdsQmODSE2SMeta?.alias || [],
    redirect: _3_45boat_45selectdsQmODSE2SMeta?.redirect || undefined,
    component: () => import("/app/pages/estimate/draft/3-boat-select.vue").then(m => m.default || m)
  },
  {
    name: _4_45boat_45formZGFG7jiG9DMeta?.name ?? "estimate-draft-4-boat-form",
    path: _4_45boat_45formZGFG7jiG9DMeta?.path ?? "/estimate/draft/4-boat-form",
    meta: _4_45boat_45formZGFG7jiG9DMeta || {},
    alias: _4_45boat_45formZGFG7jiG9DMeta?.alias || [],
    redirect: _4_45boat_45formZGFG7jiG9DMeta?.redirect || undefined,
    component: () => import("/app/pages/estimate/draft/4-boat-form.vue").then(m => m.default || m)
  },
  {
    name: indexU5dWuhranxMeta?.name ?? "estimate",
    path: indexU5dWuhranxMeta?.path ?? "/estimate",
    meta: indexU5dWuhranxMeta || {},
    alias: indexU5dWuhranxMeta?.alias || [],
    redirect: indexU5dWuhranxMeta?.redirect || undefined,
    component: () => import("/app/pages/estimate/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  }
]